import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Badge,
  Container,
  CircularProgress,
  Tab,
  Tabs,
  Typography,
  CssBaseline,
} from "@material-ui/core";

import JokeCard from "./JokeCard";

const useStyles = makeStyles({
  layout: {},
  button: {
    margin: "0 .2rem 0 0",
  },
  card: {
    marginTop: "1rem",
  },
  container: {
    background: "#6AA4FF",
    ["@media (min-width: 780px)"]: {
      marginTop: "2rem",
    },
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .3)",
    padding: "2rem 2rem",
    marginTop: 0,
  },
  body: {
    marginTop: "1rem",
    color: "#ffffff",
  },
});

const Spinner: React.FC = () => {
  return (
    <div style={{ textAlign: "center", padding: "2rem" }}>
      <CircularProgress />
    </div>
  );
};

const App: React.FC = () => {
  const classes = useStyles();

  const [jokes, setJokes] = useState([]);
  const [jokesToShow, setJokesToShow] = useState([]);
  const [likedJokes, setLikedJokes] = useState<any>([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch("https://api.icndb.com/jokes/random/574?firstName=Noah&lastName=Wong")
      .then((res) => res.json())
      .then((res) => {
        setJokes(res.value);
        setJokesToShow(res.value.slice(0, 10));
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const likeJoke = (id: number) => {
    if (likedJokes.find((j: any) => j.id === id)) return;
    const likedJoke: [] | undefined = jokes.find((j: any) => j.id === id);
    setLikedJokes([likedJoke, ...likedJokes]);
  };

  const unlikeJoke = (id: number) => {
    const newLikedJokes = likedJokes.filter((j: any) => j.id !== id);
    setLikedJokes(newLikedJokes);
  };

  const changeTab = (event: any, value: number) => {
    setCurrentTab(value);
  };

  const addMoreJokes = () => {
    setLoading(true);
    setTimeout(() => {
      setJokesToShow(jokes.slice(0, jokesToShow.length + 10));
      setLoading(false);
    }, 800);
  };

  const observeElement = (bottomJoke: any) => {
    if (!bottomJoke) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting === true) {
          console.log("Reached bottom of card");
          addMoreJokes();
          observer.unobserve(bottomJoke);
        }
      },
      {
        threshold: 1,
      }
    );

    observer.observe(bottomJoke);
  };

  useEffect(() => {
    const bottomJokeEl = document.getElementById(
      `joke-${jokesToShow.length - 1}`
    );
    observeElement(bottomJokeEl);
  }, [jokesToShow]);

  return (
    <div className={classes.layout}>
      <Container className={classes.container}>
        <CssBaseline />
        <Typography variant="h3" align="center">
          Noah Wong Jokes
        </Typography>
        <AppBar position="sticky" style={{ marginBottom: "1rem" }}>
          <Tabs value={currentTab} onChange={changeTab} centered>
            <Tab label="Home" id="home-tab" aria-controls="home-panel" />
            <Tab
              label={
                <Badge
                  color="secondary"
                  badgeContent={
                    likedJokes.length > 0 ? likedJokes.length : null
                  }
                >
                  Likes
                </Badge>
              }
              id="likes-tab"
              aria-controls="likes-panel"
            />
          </Tabs>
        </AppBar>
        <div role="tabpanel" hidden={currentTab !== 0}>
          {jokesToShow.map((joke: any, index) => (
            <JokeCard
              key={joke.id}
              joke={joke}
              likeJoke={likeJoke}
              unlikeJoke={unlikeJoke}
              index={index}
            />
          ))}
          {loading && <Spinner />}
        </div>
        <div role="tabpanel" hidden={currentTab !== 1}>
          {likedJokes.map((joke: any) => {
            return (
              <JokeCard
                key={joke.id}
                joke={joke}
                likeJoke={likeJoke}
                unlikeJoke={unlikeJoke}
              />
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default App;
