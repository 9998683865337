import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  Card,
  CardActions,
  CardContent,
  Chip,
} from "@material-ui/core";

const useStyles = makeStyles({
  button: {
    margin: "0 .2rem 0 0",
  },
  card: {
    marginTop: "1rem",
  },
});

const Category = withStyles({
  root: {
    marginBottom: "1rem",
  },
})(Chip);

const JokeCard = ({
  joke,
  likeJoke,
  unlikeJoke,
  index,
}: {
  joke: any;
  likeJoke: any;
  unlikeJoke: any;
  index?: any;
}) => {
  const classes = useStyles();

  return (
    <Card key={joke.id} className={classes.card} id={`joke-${index}`}>
      <CardContent>
        {/* {joke.categories.length > 0 ? (
          joke.categories.map((category: any) => (
            <Category label={category} variant="outlined" key={category} />
          ))
        ) : (
          <Category label="normal" variant="outlined" />
        )} */}
        <Typography>
          <span dangerouslySetInnerHTML={{ __html: `${joke.joke}` }} />
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => likeJoke(joke.id)}
        >
          Like
        </Button>
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={() => unlikeJoke(joke.id)}
        >
          Unlike
        </Button>
      </CardActions>
    </Card>
  );
};

export default JokeCard;
